var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-col',{staticClass:"pa-0 mb-10",attrs:{"align":"start","justify":"center"}},[_c('v-icon',{staticStyle:{"cursor":"pointer"},attrs:{"color":"primary"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiChevronLeft)+" ")]),_c('h3',{staticClass:"ms-4",staticStyle:{"display":"inline-block"}},[_vm._v(" Detail Disbursement ")])],1),_c('header-info',{staticStyle:{"margin-left":"0","margin-right":"0","margin-bottom":"2rem"},attrs:{"titles":_vm.titles,"values":_vm.values,"color":_vm.disbursementStatusColor[_vm.disbursement.status]}}),(_vm.disbursement.information)?_c('v-card',{staticClass:"pa-4 mb-8",attrs:{"outlined":""},domProps:{"innerHTML":_vm._s(_vm.disbursement.information)}}):_vm._e(),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.disbursement.details,"item-key":"id"},scopedSlots:_vm._u([{key:"item.no",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.disbursement.details.findIndex(function (employee) { return employee.id === item.id; }) + 1)+". ")]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.employeeName)+" ")]),_c('div',[_vm._v(_vm._s(item.phoneNumber))])])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formattedCurrency(item.amount))+" ")]}},{key:"item.currentTotalLoan",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.toBillingPage(item.loanSubsId[0])}}},[_vm._v(" "+_vm._s(_vm.formattedCurrency(item.currentTotalLoan))+" ")])]}},{key:"item.totalReceived",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formattedCurrency(item.totalReceived))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{style:(("color: " + (_vm.employeeStatusColor[item.status])))},[_vm._v(" "+_vm._s(_vm.employeeStatusText[item.status])+" ")])]}},{key:"item.paidDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.datePaid ? _vm.formatDate(item.datePaid) : "--")+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }