<template>
  <v-container>
    <!-- Header -->
    <v-col
      align="start"
      justify="center"
      class="pa-0 mb-10"
    >
      <v-icon
        color="primary"
        style="cursor: pointer;"
        @click="$router.go(-1)"
      >
        {{ icons.mdiChevronLeft }}
      </v-icon>
      <h3
        style="display: inline-block"
        class="ms-4"
      >
        Detail Disbursement
      </h3>
    </v-col>

    <!-- Header Info -->
    <header-info
      :titles="titles"
      :values="values"
      :color="disbursementStatusColor[disbursement.status]"
      style="margin-left: 0; margin-right: 0; margin-bottom: 2rem;"
    />

    <v-card
      v-if="disbursement.information"
      outlined
      class="pa-4 mb-8"
      v-html="disbursement.information"
    ></v-card>

    <!-- Table -->
    <v-data-table
      :headers="headers"
      :items="disbursement.details"
      item-key="id"
      class="elevation-1"
    >
      <template v-slot:item.no="{ item }">
        {{ disbursement.details.findIndex((employee) => employee.id === item.id) + 1 }}.
      </template>

      <template v-slot:item.name="{ item }">
        <div class="d-flex flex-column">
          <div class="font-weight-bold">
            {{ item.employeeName }}
          </div>
          <div>{{ item.phoneNumber }}</div>
        </div>
      </template>

      <template v-slot:item.amount="{ item }">
        {{ formattedCurrency(item.amount) }}
      </template>

      <template v-slot:item.currentTotalLoan="{ item }">
        <div
          style="cursor: pointer"
          @click="toBillingPage(item.loanSubsId[0]) "
        >
          {{ formattedCurrency(item.currentTotalLoan) }}
        </div>
      </template>

      <template v-slot:item.totalReceived="{ item }">
        {{ formattedCurrency(item.totalReceived) }}
      </template>

      <template v-slot:item.status="{ item }">
        <div :style="`color: ${employeeStatusColor[item.status]}`">
          {{ employeeStatusText[item.status] }}
        </div>
      </template>

      <template v-slot:item.paidDate="{ item }">
        {{ item.datePaid ? formatDate(item.datePaid) : "--" }}
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import useAppConfig from '@core/@app-config/useAppConfig'
import { mdiChevronLeft } from '@mdi/js'

import HeaderInfo from './components/HeaderInfo.vue'

export default {
  name: 'DisbursementDetail',
  components: { HeaderInfo },
  setup() {
    const { isDark } = useAppConfig()
    const employeeStatusText = {
      waitingApproval: 'Waiting Approval',
      approved: 'Approved',
      sent: 'Sent',
      paid: 'Paid',
    }
    const employeeStatusColor = {
      waitingApproval: '#FCBE2D',
      approved: '#9E9E9E',
      sent: '#03A9F4',
      paid: '#44A780',
    }
    const disbursementStatusColor = {
      done: '#44A780',
      inProgress: '#FCBE2D',
      approved: '#9E9E9E',
      rejected: '#FF6666',
      waitingApproval: '#999999',
    }

    return {
      isDark,
      employeeStatusText,
      employeeStatusColor,
      disbursementStatusColor,
      icons: { mdiChevronLeft },
    }
  },
  data() {
    return {
      titles: ['Disbursement date', 'Employee', 'Employee paid', 'Amount', 'Status'],
      values: [],
      headers: [
        {
          text: 'No.',
          align: 'start',
          value: 'no',
        },
        {
          text: 'Name',
          align: 'start',
          value: 'name',
        },
        { text: 'Amount', align: 'end', value: 'amount' },
        { text: 'Loan', align: 'end', value: 'currentTotalLoan' },
        { text: 'Received', align: 'end', value: 'totalReceived' },
        { text: 'Status', align: 'start', value: 'status' },
        {
          text: 'Paid date',
          align: 'start',
          value: 'paidDate',
        },
      ],
    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/getUser']
    },
    disbursement() {
      return this.$store.getters['manageSalary/getCurrentDisbursement']
    },
  },
  mounted() {
    const amountTotalEmployeeWithStatusPaid = this.disbursement.details.reduce((total, disbursementItem) => {
      if (disbursementItem.status === 'paid') total += 1

      return total
    }, 0)
    this.values = [
      this.formatDate(this.disbursement.disbursementDate),
      this.disbursement.employees.length,
      amountTotalEmployeeWithStatusPaid,
      this.formattedCurrency(this.disbursement.disbursementTotalAmount),
      this.disbursement.status,
    ]
  },
  methods: {
    formattedCurrency(number) {
      return number ? new Intl.NumberFormat('id-ID').format(number) : 0
    },
    formatDate(date) {
      return this.$moment(new Date(date - new Date().getTimezoneOffset())).format('DD MMM YYYY HH:mm')
    },
    toBillingPage(loanSubId) {
      this.$router.push({ path: `/manage-loan/manage-billing/${loanSubId}` })
    },
  },
}
</script>

<style lang="scss" scoped>
.v-btn.action-btn {
  width: 36px;
  min-width: 36px;
}
</style>
